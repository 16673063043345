@charset "UTF-8";
html {
    color: #000;
    background: #fff;
    font-family: 'Source Han SansCN Regular';
}

body,
dd,
div,
dl,
dt,
h1,
h2,
h3,
h4,
h5,
h6,
html,
li,
ol,
p,
strong,
td,
ul {
    padding: 0;
    margin: 0;
    font-family: 'Source Han SansCN Regular';
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

fieldset,
img {
    border: 0
}

a {
    text-decoration: none;
    color: #00c;
    outline: 0
}

em,
strong,
var {
    font-style: normal
}

address,
caption,
cite,
code,
dfn,
em,
optgroup,
strong,
th,
var {
    font-style: inherit;
    font-weight: inherit
}

del,
ins {
    text-decoration: none
}

li {
    list-style: none
}

caption,
th {
    text-align: left
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 400
}

q:after,
q:before {
    content: ''
}

abbr,
acronym {
    border: 0;
    font-variant: normal
}

sup {
    vertical-align: baseline
}

sub {
    vertical-align: baseline
}

legend {
    color: #000
}

button,
input,
optgroup,
option,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit
}

input {
    border: none;
    outline: 0
}

abbr,
article,
aside,
audio,
canvas,
datalist,
details,
dialog,
eventsource,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
time,
video {
    display: block
}

* {
    -webkit-tap-highlight-color: transparent
}

.clearfix:after {
    content: "\200B";
    display: block;
    height: 0;
    clear: both
}